module.exports = {
	confirmation: function (question, confirmButtonCaption, callback) {
		if (confirmButtonCaption === null) {
			confirmButtonCaption = language.get('global.ok');
		}

		const modal = $('#js-modal-confirmation');
		const body = modal.find('.modal-body > p');
		const buttons = modal.find('.modal-footer > .btn');

		body.text(question);

		const confirmButton = buttons.filter('[data-action="ok"]');
		confirmButton.text(confirmButtonCaption);

		const closeFunc = function () {
			modal.off('hidden.bs.modal');
			buttons.off('click');

			const btn = $(this);
			const action = btn.data('action');
			switch(action) {
				case 'ok':
					callback();
					break;

				case undefined:
				case 'cancel':
					break;

				default:
					console.error('[modals.confirmation] Unknown action', action);
					break;
			}
		};

		modal.on('hidden.bs.modal', closeFunc);
		buttons.on('click', closeFunc);

		modal.modal();
	}
};
