$(function () {
	const $rootElements = $('.js-message-editor');

	for (let i = 0; i < $rootElements.length; i++) {
		const $root = $($rootElements[i]);

		const $editor = $root.find('textarea');
		const editor = $editor[0];

		const fieldName = $editor.attr('id');

		const previewUrl = $('#' + fieldName + '-preview-url').val();
		const csrfToken = $('input[name=_token]').val();

		if (editor !== undefined) {
			// Preview rendering
			const $preview = $('#' + fieldName + '-preview');
			const $toolbar = $root.find('.editor-toolbar > div');

			// Formatting UI
			function insertTag(tag, insertOnlyBefore) {
				let before = tag;
				let end = tag;

				if (typeof insertOnlyBefore === 'string') {
					end = insertOnlyBefore;
					insertOnlyBefore = false;
				}

				insertOnlyBefore = !!insertOnlyBefore;

				const start = editor.selectionStart;
				const finish = editor.selectionEnd;

				if (insertOnlyBefore) {
					editor.value = editor.value.substr(0, start) + before + editor.value.substr(start);
				}
				else {
					editor.value = editor.value.substr(0, start) + before + editor.value.substr(start, finish - start) + end + editor.value.substr(finish);
				}
			}

			function prefixLines(tag) {
				let start = editor.value.lastIndexOf('\n', editor.selectionStart);
				let end = editor.value.indexOf('\n', editor.selectionEnd);

				if (start === -1) {
					start = 0;
				}

				if (end === -1) {
					end = editor.value.length;
				}

				console.log(start, end);

				let text = editor.value.substring(start, end);
				text = text.replace(/\n/g, '\n' + tag);

				if (start === 0) {
					text = tag + text;
				}

				editor.value = editor.value.substr(0, start) + text + editor.value.substr(end);
			}

			$root.find('button[data-editor=bold]').click(insertTag.bind(null, '__', false));
			$root.find('button[data-editor=italic]').click(insertTag.bind(null, '_', false));
			$root.find('button[data-editor=strikethrough]').click(insertTag.bind(null, '~~', false));

			$root.find('button[data-editor=list-ul]').click(prefixLines.bind(null, '* '));
			$root.find('button[data-editor=list-ol]').click(prefixLines.bind(null, '1. '));

			$root.find('button[data-editor=code]').click(insertTag.bind(null, '`', false));
			$root.find('button[data-editor=quote]').click(prefixLines.bind(null, '> '));

			$root.find('button[data-editor=link]').click(insertTag.bind(null, '[', '](' + language.get('global.linkAddress') + ')'));
			$root.find('button[data-editor=image]').click(insertTag.bind(null, '![', '](' + language.get('global.imageAddress') + ')'));

			$root.find('button[data-editor=h1]').click(prefixLines.bind(null, '# '));

			// Preview/Source switching
			$root.find('input[name=' + fieldName + '_view_source]').parent().click(setTimeout.bind(null, function () {
				const viewSource = $root.find('input[name=' + fieldName + '_view_source]:checked').val() === "true";
				if (viewSource) {
					$toolbar.show();
					$editor.show();
					$preview.hide();
				}
				else {
					$toolbar.hide();
					$editor.hide();
					$preview.show();

					const text = editor.value.trim();

					if (text.length === 0) {
						$preview.html('');
						$preview.height($editor.height());
					}
					else {

						$preview.height('');
						$preview.html('<h1 class="text-center"><i class="fa fa-spin fa-circle-notch"></i></h1>');
						$.post(previewUrl, {_token: csrfToken, text: text}, (html) => {
							$preview.html(html);
						}).fail((p) => {
							console.log(p);
							$preview.html('<div class="alert alert-danger">' + window.language.get('global.serverRequestFailed') + '</div>');
						});
					}
				}
			}, 0));
		}
	}
});
