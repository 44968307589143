import * as modals from "./modals";
import * as toast from "./toast";

const $comments = $('.js-comment');

for (let i = 0; i < $comments.length; i++) {
	const $item = $($comments[i]);
	const $deleteAction = $item.find('.js-comment-delete');

	$deleteAction.data('href', $deleteAction.attr('href'));
	$deleteAction.attr('href', 'javascript:void(0)');

	$deleteAction.click(function () {
		const link = $(this);
		modals.confirmation(language.get('global.delete.confirm'), null, () => {
			$.post(link.data('href'), {
				'_token': window.csrfToken,
			})
			.done(() => {
				$item.remove();
				toast.notify(language.get('global.delete.success'));
			})
			.fail(() => {
				toast.notify(language.get('global.serverRequestFailed'));
			});
		});
	});
}
