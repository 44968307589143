module.exports = {
	notify: function (text, title) {
		const $item = $('#toast-item-template').clone();
		$item.find('.toast-title').text(title);
		$item.find('.toast-body').text(text);

		$('#toast-panel').append($item);

		$item.attr('style', 'float: right;');
		$item.hide();
		$item.show(200, () => {
			$item.attr('style', '');
		});

		const closeItemFunc = () => {
			$item.animate({
				height: "hide",
				marginBottom: "hide",
				marginLeft: "hide",
				marginRight: "hide",
				marginTop: "hide",
				opacity: "hide",
				paddingBottom: "hide",
				paddingLeft: "hide",
				paddingRight: "hide",
				paddingTop: "hide",
			}, 500, () => {
				$item.remove();
			});
		};

		setTimeout(closeItemFunc, 5000);
		$item.find('.close').click(closeItemFunc);
	}
};
