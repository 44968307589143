/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require('jquery');

require('bootstrap');

window.modals = require('./modals');
window.toast = require('./toast');

require('./comments');
require('./message_editor');
require('./limited_textbox');

const autosize = require('autosize');
autosize($('.js-autosize'));

$(() => {
	$('[data-toggle="tooltip"]').tooltip()
});
