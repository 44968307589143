$(function () {
	function updateGauge(box, limitGauge) {
		const limit = box.attr('maxlength');
		limitGauge.text(box.val().length + ' / ' + limit);
	}

	const textboxes = $('.js-limited-textbox');

	for (let i = 0; i < textboxes.length; i++) {
		const box = $(textboxes[i]);
		const limitGauge = $('#' + box.attr('id') + '-limit-gauge');

		updateGauge(box, limitGauge);
		box.bind('input propertychange', () => {
			updateGauge(box, limitGauge);
		});
	}
});
